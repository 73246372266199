:root {
  --text-color: #131513;
  --background-color: #f1f2f1;
  --blue-section: #3043f1;
  --link-color: #a7590b;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibilit;
}

body {
  background-color: var(--background-color);
  font-family: "Ubuntu", sans-serif;
  color: var(--text-color);
}

h2 {
  font-size: 2rem;
  font-weight: 400;
}

h3 {
  font-size: 1.375rem;
  font-weight: 400;
}

header {
  padding: 1rem;
  border-bottom: 1px #000;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: var(--background-color);
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header img {
  width: 100px;
}

.menu-button {
  display: none;
  border: 1px solid var(--text-color);
  background-color: transparent;
  padding: 0.7rem 0.7rem;
  border-radius: 15px;
}

.menu-button.close {
  border: 1px solid #fff;
  z-index: 100;
  position: fixed;
  right: 1rem;
  top: 1rem;
}

.menu-button.close svg {
  color: #f1f2f1;
}

.menu-button img {
  width: 20px;
  height: 20px;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

nav li a {
  text-decoration: none;
  font-weight: 400;
  color: var(--text-color);
  font-family: "Archivo", sans-serif;
}

.container {
  max-width: 1290px;
  margin: 0 auto;
}

#hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

#hero .tagline {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#hero .tagline h1 {
  font-size: 2.5rem;
  margin: 0;
}

#hero .tagline p {
  max-width: 526px;
  text-align: center;
  font-size: 1.2rem;
}

#hero video {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: auto;
}

#hero .platforms {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 1rem;
}

#features {
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

#specs {
  padding: 5rem 0;
  background-color: #fff;
}

#specs .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

#specs img {
  width: 100%;
}

#specs h2 {
  font-size: 2rem;
  font-weight: 400;
}

#specs .row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 3rem;
  max-width: 56rem;
}

#specs .column {
  flex: 0 0 33.33333%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 0.8rem;
}

#specs .column p {
  margin: 0;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 140%;
}

#specs .column h3 {
  margin-bottom: 0.7rem;
  font-size: 0.875rem;
  font-weight: 700;
}

#specs .column ul {
  margin-top: 0;
  padding-left: 1rem;
}

#downloads {
  background-color: var(--blue-section);
  color: #f1f2f1;
  display: flex;
}

#downloads .content {
  padding: 5rem;
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#downloads .text {
  max-width: 400px;
  margin: 0 auto;
}

#downloads .text a {
  color: rgba(241, 242, 241, 0.7);
}

#downloads .content h2 {
  font-size: 2rem;
  font-weight: 400;
}

#downloads .content p {
  color: rgba(241, 242, 241, 0.7);
}

#downloads ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#downloads ul li {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: start;
}

#downloads .image {
  width: 100%;
}

#downloads .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#galery {
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
}

#galery .title {
  width: 50%;
}

#galery h2 {
  font-size: 2rem;
  font-weight: 400;
  margin: 0 auto;
  max-width: 400px;
}

#galery .subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#galery .subtitle p {
  color: rgba(19, 21, 19, 0.7);
  max-width: 28rem;
  margin-left: 8rem;
}

#galery .glider {
  margin-top: 2rem;
  width: 100%;
  height: 100%;
}

#galery .navigator,
#galery .navigator {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#galery .glider img {
  width: 290px;
  height: 354px;
  object-fit: cover;
  cursor: pointer;
}

#galery .glider-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

#bolha {
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

#bolha h2 {
  max-width: 480px;
  text-align: center;
}

#bolha .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

#bolha .company-card {
  display: flex;
  flex: 0 0 430px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 2rem;
  box-sizing: border-box;
}

#bolha h3 {
  max-width: 545px;
  text-align: center;
  line-height: 2rem;
}

#bolha .members {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem; /* Adjust the gap size as needed */
  justify-content: center;
  width: max-content; /* To ensure the grid is centered based on its content */
  margin: 0 auto;
}

#bolha .member {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#bolha .members p,
#bolha .members h4 {
  margin: 0;
  padding: 0;
  text-align: center;
}

#bolha .members h4 {
  margin-bottom: 0.5rem;
}

#faq {
  background-color: #08263a0d;
  padding: 5rem 1rem;
}

#faq > .container {
  max-width: 62rem;
}

footer {
  padding: 5rem 1rem;
  background-color: #08263a0d;
  text-align: center;
}

footer p,
a {
  color: #798679;
}

#blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(15px);
  z-index: 1;
}

/* ACCORDION */

.accordion {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: 0 auto;
  overflow: hidden;
}

.accordion-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-label {
  display: block;
  padding: 1rem 3rem 1rem 1rem;
  cursor: pointer;
  position: relative;
  font-size: 1.25rem;
  font-weight: 400;
}

.accordion-label::after {
  content: "+";
  font-size: 1.4rem;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.accordion-checkbox:checked + .accordion-label::after {
  content: "-";
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 1rem;
}

.accordion-content p {
  margin: 0;
  font-size: 1rem;
}

.accordion-content a {
  color: var(--link-color);
}

.accordion-checkbox:checked + .accordion-label + .accordion-content {
  max-height: 500px;
}

.accordion-checkbox {
  display: none;
}

/* CARD */

.card {
  display: flex;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
}

.card-image {
  flex: 0 0 60%;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.card-content {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  gap: 1rem;
}

.card-content h2 {
  font-size: 1.4rem;
  font-weight: 400;
}

.card-content p {
  color: rgba(19, 21, 19, 0.7);
  font-size: 1.2rem;
  line-height: 150%;
}

/* Lightbox */
.galery-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
}

.lightbox-open {
  overflow: hidden;
}

.galery-lightbox #lightbox-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.galery-lightbox .close-lightbox {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  background: rgba(241, 242, 241, 0.7);
  border-radius: 1rem;
  border: 1px solid rgba(19, 21, 19, 0.25);
  display: inline-flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
}

.galery-lightbox .close-lightbox img {
  color: var(--text-color);
}

.galery-lightbox .lightbox-navigation {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 2rem;
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  border: 1px rgba(241, 242, 241, 0.2);
  max-width: 100%;
  flex-wrap: nowrap;
  font-weight: 700;
  width: 14.25rem;
}

.galery-lightbox .navigation-button {
  cursor: pointer;
  background: none;
  border: none;
}

.hidden {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease, visibility 0s linear 0.5s;
  visibility: hidden;
}
.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease;
}

@media screen and (max-width: 1290px) {
  #galery .subtitle p {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  nav {
    display: none;
  }

  nav.active {
    display: flex;
    z-index: 100;
  }

  nav ul {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000;
    margin: 0;
    padding: 0;
    gap: 2rem;
  }

  nav ul li a {
    color: #fff;
  }

  .menu-button {
    display: flex;
  }

  #hero .tagline {
    max-width: 100%;
    text-align: center;
  }

  #hero .platforms {
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }

  #features {
    gap: 2rem;
  }

  #specs {
    padding: 5rem 2rem;
  }

  #specs img {
    display: none;
  }

  #specs .row {
    row-gap: 0.8rem;
    width: 100%;
  }

  #specs .column {
    flex: 0 0 100%;
  }

  #downloads {
    flex-direction: column;
  }

  #downloads .content {
    padding: 3rem 2rem;
    text-align: center;
  }

  #galery .title {
    text-align: center;
    width: 100%;
  }

  #galery .subtitle {
    flex-direction: column;
    text-align: center;
  }

  #galery .glider-controls {
    display: none;
  }

  #bolha .company-card {
    flex: 0 0 100%;
  }

  #bolha .members {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    width: 100%;
    margin: 0;
  }

  .card {
    flex-direction: column;
    align-items: center;
    border-radius: 0;
  }

  .card-image {
    flex: 0 0 100%;
  }

  .card-content {
    text-align: center;
    order: 1;
    padding: 1rem;
  }
}
